<template>
  <v-form @submit.prevent="searchTool" class="mr-1">
    <v-list-item-action class="ma-0" v-if="$store.state.search.searchByID">
      <v-text-field
        placeholder="Поиск по ID"
        type="number"
        min="0"
        oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
        v-model="searchTarget"
        style="width: 120px"
      ></v-text-field>
    </v-list-item-action>
    <v-btn
      type="submit"
      icon
      outlined
      title="Поиск по ID"
      :class="{ 'primary--text': searchTarget !== null }"
      :disabled="
        $store.state.search.searchByCoord ||
          $store.state.search.searchByAddress ||
          $store.state.search.searchBySerial
      "
    >
      <v-icon>mdi-feature-search-outline</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";
import { fromLonLat } from "ol/proj";

export default {
  name: "SearchObject",

  data: () => ({
    searchTarget: null
  }),

  methods: {
    async searchTool() {
      this.$store.commit("updateSrchID", !this.$store.state.search.searchByID);
      if (this.searchTarget) {
        let layer = "";
        if (this.$store.state.settings.layerTarget !== 0)
          layer = `&layer_id=${this.$store.state.settings.layerTarget}`;
        let response;
        response = await Vue.axios.get(
          `${URL_API}plant/getbyfilter?id=${this.searchTarget}${layer}`
        );
        let data = response["data"];

        let coordinatesX;
        let coordinatesY;
        let type;
        if (data.features.length > 0) {
          type = data.features[0].geometry.type;
          if (type === "Point") {
            coordinatesX = data.features[0].geometry.coordinates[0];
            coordinatesY = data.features[0].geometry.coordinates[1];
          } else if (type === "Polygon") {
            coordinatesX = data.features[0].geometry.coordinates[0][0][0];
            coordinatesY = data.features[0].geometry.coordinates[0][0][1];
          } else if (type === "LineString") {
            coordinatesX = data.features[0].geometry.coordinates[0][0];
            coordinatesY = data.features[0].geometry.coordinates[0][1];
          }

          var view = this.$store.state.settings.map.getView();
          view.setCenter(fromLonLat([coordinatesX, coordinatesY]));
          view.setZoom(21);
          this.$emit("search-data", data.features[0].properties.id);
        } else {
          await this.$store.commit("updateMessage", "Данные не найдены", {
            root: true
          });
          this.$emit("search-message");
        }
        this.searchTarget = null;
      }
    }
  }
};
</script>
