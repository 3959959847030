<template>
  <vl-style-func :factory="districtStyle" />
</template>

<script>
import { Fill, Stroke, Style, Text } from "ol/style";

export default {
  methods: {
    districtStyle() {
      return feature => {
        let baseStyle = new Style({
          fill: new Fill({
            color:
              this.$store.state.user.userRole === 5
                ? feature.get("id") ===
                  this.$store.state.user.currentUser.district_id
                  ? String(feature.get("color"))
                  : "rgba(255,255,255, 0.5)"
                : String(feature.get("color"))
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 2
          }),
          text: new Text({
            text:
              String(feature.get("name")) + ": " + String(feature.get("count")),
            scale: 1.5
          })
        });
        return [baseStyle];
      };
    }
  }
};
</script>
