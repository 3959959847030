<template>
  <vl-style-func :factory="clusterGridStyle" />
</template>

<script>
import { Fill, Stroke, Style, Text } from "ol/style";

export default {
  methods: {
    clusterGridStyle() {
      return feature => {
        let baseStyle = new Style({
          fill: new Fill({
            color: String(feature.get("color"))
          }),
          stroke: new Stroke({
            color: "rgba(0,0,255, 1)",
            width: 1
          }),
          text: new Text({
            text: feature.get("name")
              ? String(feature.get("name")) +
                ": " +
                String(feature.get("count"))
              : String(feature.get("count")),
            scale: 1.5
          })
        });
        return [baseStyle];
      };
    }
  }
};
</script>
