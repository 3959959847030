<template>
  <div>
    <v-navigation-drawer width="275" bottom right absolute>
      <v-list>
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="36">mdi-filter</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            ><h3 class="text-wrap">
              Управление фильтрами
            </h3>
          </v-list-item-title>
          <v-list-item-action>
            <v-btn icon @click="$emit('close-filters')">
              <v-icon size="32">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Районы</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredDistricts" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeDisctrict($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Объекты</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredTypes" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeType($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Породы</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredBreeds" :key="i">
            <v-checkbox
                :value="item.value"
                :label="item.name"
                @change="changeBreed($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Состояние</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredSanitary" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeSanitary($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Статус</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredStatuses" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeStatus($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Хоз. мероприятия</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredEvents" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeEvent($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Вредители</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredVermin" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeVermin($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-group :value="false">
          <template v-slot:activator>
            <v-list-item-title>Болезни</v-list-item-title>
          </template>

          <v-list-item v-for="(item, i) in filteredDiseases" :key="i">
            <v-checkbox
              :value="item.value"
              :label="item.name"
              @change="changeDiseas($event, i)"
            ></v-checkbox>
          </v-list-item>
        </v-list-group>

        <v-list-item-group>
          <v-list-item style="height: 48px">
            <v-checkbox
              :value="$store.state.settings.redbookTarget"
              label="Краснокнижные"
              @change="changeRedbook"
            ></v-checkbox>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group>
          <v-list-item style="height: 48px">
            <v-checkbox
              v-model="filteredComment"
              label="Скрыть комментарии"
              key="comment"
            ></v-checkbox>
          </v-list-item>
        </v-list-item-group>

        <v-col align="center">
          <v-btn
            type="button"
            class="ml-5 mt-2 mb-2 font-weight-bold"
            @click="discardFilters"
            >Сбросить фильтры
          </v-btn>

          <v-btn
            type="button"
            color="info"
            class="ml-2 mb-2 font-weight-bold"
            @click="downloadReport"
            >Сформировать отчёт
          </v-btn>

          <v-btn
            type="button"
            color="info"
            class="ml-2 mb-2 font-weight-bold"
            @click="count"
            >Количество объектов
          </v-btn>
        </v-col>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Vue from "vue";
import { URL_API } from "../../settings/apiConnect";

export default {
  name: "Filters",

  props: { updateDistricts: Function, updateClusters: Function },

  data() {
    return {
      index: 1,
      amountData: []
    };
  },

  computed: {
    filteredDistricts() {
      let arr = [];
      if (this.$store.state.user.userRole === 5) {
        this.$store.state.settings.disctricts.filter(el => {
          if (el.id === this.$store.state.user.currentUser.district_id)
            arr.push(el);
        });
        return arr;
      } else {
        this.$store.state.settings.disctricts.forEach(el => {
          arr.push(el);
        });
        return arr;
      }
    },
    filteredTypes() {
      return this.$store.state.settings.types;
    },
    filteredBreeds() {
      return this.$store.state.settings.breeds;
    },
    filteredSanitary() {
      return this.$store.state.settings.sanitary;
    },
    filteredStatuses() {
      return this.$store.state.settings.statuses;
    },
    filteredEvents() {
      return this.$store.state.settings.events;
    },
    filteredVermin() {
      return this.$store.state.settings.vermin;
    },
    filteredDiseases() {
      return this.$store.state.settings.diseases;
    },
    filteredComment: {
      get() {
        return this.$store.state.settings.commentHide;
      },
      set(value) {
        this.$store.commit("setFiltredComment", value);
      }
    }
  },

  methods: {
    ...mapActions(["getPlantByExcel"]),
    discardFilters() {
      this.$store.commit(
        "updateFilteredDisctricts",
        this.$store.state.districts.districtsData
      );
      this.$store.commit(
        "updateFilteredTypes",
        this.$store.state.types.typesData
      );
      this.$store.commit(
          "updateFilteredBreeds",
          this.$store.state.breeds.breedsData
      );
      this.$store.commit(
        "updateFilteredSanitary",
        this.$store.state.sanitary.sanitaryData
      );
      this.$store.commit(
        "updateFilteredStatuses",
        this.$store.state.statuses.statusesData
      );
      this.$store.commit(
        "updateFilteredEvents",
        this.$store.state.events.eventsData
      );
      this.$store.commit(
        "updateFilteredVermin",
        this.$store.state.vermins.verminsData
      );
      this.$store.commit(
        "updateFilteredDiseases",
        this.$store.state.diseases.diseasesData
      );
      this.$store.commit("setFiltredRedbook", false);
      this.$store.commit("setFiltredComment", false);
    },
    changeDisctrict(event, index) {
      this.$store.commit("setFilteredDistrict", { index, value: event });
    },
    changeType(event, index) {
      this.$store.commit("setFilteredType", { index, value: event });
    },
    changeBreed(event, index) {
      this.$store.commit("setFilteredBreed", { index, value: event });
    },
    changeSanitary(event, index) {
      this.$store.commit("setFilteredSanitary", { index, value: event });
    },
    changeStatus(event, index) {
      this.$store.commit("setFilteredStatus", { index, value: event });
    },
    changeEvent(event, index) {
      this.$store.commit("setFilteredEvent", { index, value: event });
    },
    changeVermin(event, index) {
      this.$store.commit("setFilteredVermin", { index, value: event });
    },
    changeDiseas(event, index) {
      this.$store.commit("setFilteredDiseas", { index, value: event });
    },
    changeRedbook() {
      this.$store.commit(
        "setFiltredRedbook",
        !this.$store.state.settings.redbookTarget
      );
    },
    async downloadReport() {
      await this.checkAmount();
      let amount = this.amountData.reduce(
        (total, array) => total + array.count,
        0
      );
      if (amount > 5000000) {
        await this.$store.commit(
          "updateMessage",
          amount + " объектов слишком много для формирования отчета",
          {
            root: true
          }
        );
        this.$emit("filter-message");
      } else {
        let isContinue = confirm(`Для отчёта выбрано ${amount} объектов. Время формирования отчёта ~${amount/300000} минут. Продолжить?`);
        if (!isContinue) return;
        this.getPlantByExcel();
      }
    },
    count() {
      this.updateDistricts();
      this.updateClusters();
    },
    async checkAmount() {
      let districts = "";
      if (this.$store.state.settings.ids_districts)
        districts = `district_id=${this.$store.state.settings.ids_districts}&`;
      let types = "";
      if (this.$store.state.settings.ids_types)
        types = `type_id=${this.$store.state.settings.ids_types}&`;
      let breeds = "";
      if (this.$store.state.settings.ids_breeds)
        breeds = `breed_id=${this.$store.state.settings.ids_breeds}&`;
      let sanitary = "";
      if (this.$store.state.settings.ids_sanitary)
        sanitary = `sanitary_id=${this.$store.state.settings.ids_sanitary}&`;
      let statuses = "";
      if (this.$store.state.settings.ids_statuses)
        statuses = `status_id=${this.$store.state.settings.ids_statuses}&`;
      let events = "";
      if (this.$store.state.settings.ids_events)
        events = `event_id=${this.$store.state.settings.ids_events}&`;
      let vermin = "";
      if (this.$store.state.settings.ids_vermin)
        vermin = `vermin_id=${this.$store.state.settings.ids_vermin}&`;
      let diseases = "";
      if (this.$store.state.settings.ids_diseases)
        diseases = `disease_id=${this.$store.state.settings.ids_diseases}&`;
      let redbook = "";
      if (this.$store.state.settings.redbookTarget)
        redbook = `redbook=${this.$store.state.settings.redbookTarget}&`;
      let layer = "";
      if (this.$store.state.settings.layerTarget !== 0)
        layer = `layer_id=${this.$store.state.settings.layerTarget}&`;
      let response;
      response = await Vue.axios.get(
        `${URL_API}type/getbyfilter?${districts}${types}${breeds}${sanitary}${statuses}${events}${vermin}${diseases}${redbook}${layer}`
      );
      this.amountData = response["data"];
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-label {
  color: #000000de;
}
</style>
