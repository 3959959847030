<template>
  <v-form @submit.prevent="searchTool" class="mr-1">
    <v-list-item-action class="ma-0" v-if="$store.state.search.searchBySerial">
      <v-text-field
        placeholder="Номер разрешения"
        min="0"
        oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
        v-model="searchTarget"
        style="width: 120px"
      ></v-text-field>
    </v-list-item-action>
    <v-btn
      type="submit"
      icon
      outlined
      title="Поиск по номеру разрешения"
      :class="{ 'primary--text': searchTarget !== null }"
      :disabled="
        $store.state.search.searchByCoord ||
          $store.state.search.searchByAddress ||
          $store.state.search.searchByID
      "
    >
      <v-icon>mdi-book-search-outline</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import Vue from "vue";
import { URL_API } from "@/settings/apiConnect";

export default {
  name: "SearchBySerial",

  data: () => ({
    searchTarget: null
  }),

  methods: {
    async searchTool() {
      this.$store.commit(
        "updateSrchSerial",
        !this.$store.state.search.searchBySerial
      );
      if (this.searchTarget) {
        let layer = "";
        if (this.$store.state.settings.layerTarget !== 0)
          layer = `&layer_id=${this.$store.state.settings.layerTarget}`;
        let response;
        response = await Vue.axios.get(
          `${URL_API}plant/getbyfilter?serial_number=${this.searchTarget}${layer}`
        );
        let data = response["data"];
        this.$store.commit("updateSerialData", data.features);
        if (data.features.length > 0) {
          this.$store.state.search.serialPassport = true;
        } else {
          await this.$store.commit("updateMessage", "Данные не найдены", {
            root: true
          });
          this.$emit("search-message");
        }
        this.searchTarget = null;
      }
    }
  }
};
</script>
