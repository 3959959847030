<template>
  <v-card style="width: 350px">
    <v-form
      ref="form"
      v-model="formValidity"
      @submit.prevent="
        setPlants({
          age: ageTarget,
          height: heigthTarget,
          diameter: diameterTarget,
          serial_number: serialNumTarget ? serialNumTarget : null,
          sanitary_id: sanitaryTarget,
          category_id: categorieTarget,
          status_id: statuseTarget ? statuseTarget : null,
          in_charge: inChargeTarget ? inChargeTarget : null,
          planting_date: plantingDateTarget ? plantingDateTarget : null,
          registry_date: addedDateTarget ? addedDateTarget : null,
          vermin: verminTarget ? verminTarget : null,
          diseases: diasesTarget ? diasesTarget : null,
          events: eventsTarget,
          bushes: [bushesTarget],
          image: image ? image : null
        })
      "
    >
      <v-col align="center">
        <h3>Паспорт объекта</h3>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-card-text class="text-start">Координаты</v-card-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card-text class="text-end">{{
              Math.trunc(geoCoordinates[0] * 1000) / 1000 +
                " : " +
                Math.trunc(geoCoordinates[1] * 1000) / 1000
            }}</v-card-text>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="9">
            <v-card-text class="text-start"
              >Идентификационный номер</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="3">
            <v-card-text class="text-end">{{ properties.id }}</v-card-text>
          </v-col>
        </v-row>
        <v-autocomplete
          :items="bushes"
          v-model="bushesTarget"
          item-text="name"
          item-value="id"
          placeholder="Порода кустарников"
          outlined
          dense
          hide-details
          class="mb-1"
          clearable
          :rules="requiredRules"
          required
          return-object
        />
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Вид насаждений</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card-text class="text-end">Кустарник</v-card-text>
          </v-col>
        </v-row>
        <v-select
          :items="categories"
          v-model="categorieTarget"
          item-text="name"
          item-value="id"
          placeholder="Категория насаждений"
          outlined
          dense
          hide-details
          class="mb-1"
          clearable
          :rules="requiredRules"
          required
        />
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Возраст</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="ageTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
              :rules="requiredRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Высота</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="heigthTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
              :rules="requiredRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Диаметр</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="diameterTarget"
              type="number"
              min="0"
              oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
              outlined
              dense
              hide-details
              clearable
              :rules="requiredRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-select
          :items="sanitary"
          v-model="sanitaryTarget"
          item-text="name"
          item-value="id"
          placeholder="Состояние"
          outlined
          dense
          hide-details
          class="mb-1"
          clearable
          :rules="requiredRules"
          required
        />
        <v-select
          :items="vermins"
          v-model="verminTarget"
          item-text="name"
          item-value="id"
          placeholder="Вредители"
          outlined
          dense
          multiple
          hide-details
          class="mb-1"
          clearable
          return-object
        />
        <v-select
          :items="diases"
          v-model="diasesTarget"
          item-text="name"
          item-value="id"
          placeholder="Болезнь"
          outlined
          dense
          multiple
          hide-details
          class="mb-1"
          clearable
          return-object
        />
        <v-select
          :items="events"
          v-model="eventsTarget"
          item-text="name"
          item-value="id"
          placeholder="Хозяйственное мероприятие"
          outlined
          dense
          multiple
          hide-details
          class="mb-1"
          clearable
          :rules="[requiredRulesArr]"
          return-object
        />
        <v-select
          :items="statuses"
          v-model="statuseTarget"
          item-text="name"
          item-value="id"
          placeholder="Статус"
          outlined
          dense
          hide-details
          class="mb-1"
          clearable
        />
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Номер разрешения</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="serialNumTarget"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Дата посадки</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu v-model="plantingDateMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="plantingDateTarget"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="plantingDateTarget" no-title scrollable>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start"
              >Дата добавления в реестр</v-card-text
            >
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu v-model="addedDateMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="addedDateTarget"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  hide-details
                  clearable
                  class="mb-1"
                ></v-text-field>
              </template>
              <v-date-picker v-model="addedDateTarget" no-title scrollable>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="4">
            <v-card-text class="text-start">Район</v-card-text>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card-text class="text-end">{{
              district ? district.name : ""
            }}</v-card-text>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-card-text class="text-start">Ответственное лицо</v-card-text>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inChargeTarget"
              type="text"
              outlined
              dense
              hide-details
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-col class="pa-0">
          <v-file-input
            v-model="image"
            type="file"
            class="input"
            accept="image/*"
            show-size
            placeholder="Добавить фото"
            prepend-icon="mdi-camera"
            outlined
            dense
            hide-details
            @change="uploadImage"
          />
        </v-col>
        <v-row
          dense
          v-if="imageUrl"
          style="max-height: 100px; max-width: 100px"
        >
          <v-col cols="12" sm="11" class="pa-0">
            <expandable-image class="image" :src="imageUrl"></expandable-image>
          </v-col>
          <v-col cols="12" sm="1" class="pa-0">
            <v-btn icon @click="clearImg">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="mt-2">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-card-text class="text-end">
                <v-btn
                  type="submit"
                  class="font-weight-bold"
                  :disabled="
                    !formValidity ||
                      $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
                  >Сохранить</v-btn
                >
              </v-card-text>
            </v-col>
            <v-col v-if="isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  @click="resetForm"
                  :disabled="
                    $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
                  >Очистить</v-btn
                ></v-card-text
              >
            </v-col>
            <v-col v-else cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-plant')"
                  :disabled="
                    $store.state.user.userRole === 4 ||
                      $store.state.user.userRole === 5
                  "
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
import { URL } from "../settings/apiConnect";
import { mapActions } from "vuex";

export default {
  name: "BushPassport",
  props: {
    setPlants: Function,
    properties: Object,
    geoCoordinates: Object,
    isDraw: Boolean,
    district: Object
  },

  data() {
    return {
      categorieTarget: null,
      ageTarget: null,
      heigthTarget: null,
      diameterTarget: null,
      sanitaryTarget: null,
      verminTarget: [],
      diasesTarget: [],
      eventsTarget: [],
      statuseTarget: null,
      serialNumTarget: null,
      plantingDateMenu: false,
      plantingDateTarget: null,
      addedDateMenu: false,
      addedDateTarget: null,
      inChargeTarget: null,
      image: undefined,
      imageUrl: "",
      imgRules: [
        value =>
          !value ||
          value.size < 100000000 ||
          "Размер фото не должен превышать 100 МБ!"
      ],
      bushesTarget: null,
      requiredRules: [value => !!value || "Required"],
      formValidity: false
    };
  },

  computed: {
    categories() {
      return this.$store.state.categories.categoriesData;
    },
    sanitary() {
      let arr = [];
      this.$store.state.sanitary.sanitaryData.filter(el => {
        if (el.id >= 1 && el.id <= 6) arr.push(el);
      });
      return arr;
    },
    vermins() {
      return this.$store.state.vermins.verminsData;
    },
    diases() {
      return this.$store.state.diseases.diseasesData;
    },
    events() {
      return this.$store.state.events.eventsData;
    },
    statuses() {
      return this.$store.state.statuses.statusesData;
    },
    bushes() {
      return this.$store.state.bushes.bushData;
    }
  },

  watch: {
    properties: function(newValue) {
      this.ageTarget = newValue.age;
      this.heigthTarget = newValue.height;
      this.diameterTarget = newValue.diameter;
      this.serialNumTarget = newValue.serial_number;
      this.sanitaryTarget = newValue.sanitary_id;
      this.categorieTarget = newValue.category_id;
      this.statuseTarget = newValue.status_id;
      this.inChargeTarget = newValue.in_charge;
      this.plantingDateTarget = newValue.planting_date;
      this.addedDateTarget = this.isDraw
        ? new Date().toISOString().substr(0, 10)
        : newValue.registry_date;
      this.verminTarget = newValue.vermin;
      this.diasesTarget = newValue.diseases;
      this.eventsTarget = newValue.events;
      if (newValue.bushes != null) {
        if (newValue.bushes.length) this.bushesTarget = newValue.bushes[0].id;
      }
      this.imageUrl = newValue.image ? URL + newValue.image : newValue.image;
    }
  },

  methods: {
    ...mapActions(["removeImg"]),
    resetForm() {
      this.$refs.form.reset();
    },
    requiredRulesArr(value) {
      if (value instanceof Array && value.length == 0) {
        return "Required";
      }
      return !!value || "Required";
    },
    uploadImage(file) {
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        this.imageUrl = e.target.result;
      };
      reader.onerror = function(error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    clearImg() {
      if (
        confirm("Желаете удалить фото у объекта " + this.properties.id + "?")
      ) {
        this.removeImg(this.properties.id);
        this.imageUrl = "";
      } else {
        this.uploadImage();
      }
    }
  },

  mounted() {
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(viewportMeta);
  }
};
</script>

<style scoped>
::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}
</style>
