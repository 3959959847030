<template>
  <vl-style-func :factory="commentStyle" />
</template>

<script>
import { Icon, Style } from "ol/style";

import commentImg from "@/assets/comment.png";

export default {
  methods: {
    commentStyle() {
      return () => {
        let baseStyle = new Style({
          image: new Icon({
            src: commentImg
          })
        });
        return [baseStyle];
      };
    }
  }
};
</script>
