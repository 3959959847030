<template>
  <v-form @submit.prevent="searchTool" class="mr-1">
    <v-list-item-action class="ma-0" v-if="$store.state.search.searchByAddress">
      <v-text-field
        placeholder="Поиск по адресу"
        type="text"
        style="width: 150px"
        v-model="addressTarget"
      ></v-text-field>
    </v-list-item-action>
    <v-list-item-action class="ma-0">
      <v-btn
        type="submit"
        icon
        outlined
        title="Поиск по адресу"
        :disabled="
          $store.state.search.searchByCoord ||
            $store.state.search.searchByID ||
            $store.state.search.searchBySerial
        "
      >
        <v-icon>mdi-home-search</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-form>
</template>

<script>
import Vue from "vue";
import { fromLonLat } from "ol/proj";

export default {
  name: "SearchByAddress",

  data: () => ({
    addressTarget: null,
    coordX: null,
    coordY: null
  }),

  methods: {
    async searchTool() {
      this.$store.commit(
        "updateSrchAdd",
        !this.$store.state.search.searchByAddress
      );
      console.dir(this.addressTarget);
      if (this.addressTarget) {
        let response = await Vue.axios.get(
          `https://catalog.api.2gis.com/3.0/items/geocode?&q=${"Алматы, " +
            this.addressTarget}&fields=items.point&key=ruuwsr0781`
        );
        console.dir(response);
        this.coordX = response.data.result.items[0].point.lon;
        this.coordY = response.data.result.items[0].point.lat;
        console.dir(this.coordX);
        console.dir(this.coordY);
        if (this.coordX && this.coordY) {
          var view = this.$store.state.settings.map.getView();
          view.setCenter(fromLonLat([this.coordX, this.coordY]));
          view.setZoom(21);
        }
        this.addressTarget = null;
        this.coordX = null;
        this.coordY = null;
      }
    }
  }
};
</script>
