<template>
  <v-list-item-action class="mr-1 ml-0">
    <v-btn
      icon
      @click="fromGPS"
      outlined
      title="GPS"
      :class="{ 'primary--text': $store.state.settings.gps }"
    >
      <v-icon>mdi-crosshairs-gps</v-icon>
    </v-btn>
  </v-list-item-action>
</template>

<script>
export default {
  name: "Gps",

  methods: {
    fromGPS() {
      this.$store.state.settings.gps = !this.$store.state.settings.gps;
      if (this.$store.state.settings.gps) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              this.$store.state.settings.zoom = 19;
              this.$store.state.settings.center = [
                position.coords.longitude,
                position.coords.latitude
              ];
            },
            error => {
              alert(error.message);
            },
            {
              timeout: 20000,
              maximumAge: 1000
            }
          );
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      }
    }
  }
};
</script>
