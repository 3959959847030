<template>
  <v-list-item-action class="mr-1">
    <v-btn icon outlined title="Печать карты" @click="printMap">
      <v-icon>mdi-printer</v-icon>
    </v-btn>
  </v-list-item-action>
</template>

<script>
import jsPDF from "jspdf";

export default {
  name: "MapPrint",
  data: () => ({
    clicked: false
  }),
  methods: {
    printMap() {
      var resolution = 72;
      var width = Math.round((420 * resolution) / 25.4);
      var height = Math.round((297 * resolution) / 25.4);
      var mapCanvas = document.createElement("canvas");
      mapCanvas.width = width;
      mapCanvas.height = height;
      var mapContext = mapCanvas.getContext("2d");
      Array.prototype.forEach.call(
        document.querySelectorAll(".ol-layer canvas"),
        function(canvas) {
          if (canvas.width > 0) {
            var opacity = canvas.parentNode.style.opacity;
            mapContext.globalAlpha = opacity === "" ? 1 : Number(opacity);
            var transform = canvas.style.transform;
            var matrix = transform
              .match(/^matrix\(([^(]*)\)$/)[1]
              .split(",")
              .map(Number);
            CanvasRenderingContext2D.prototype.setTransform.apply(
              mapContext,
              matrix
            );
            mapContext.drawImage(canvas, 0, 0);
          }
        }
      );
      var pdf = new jsPDF("landscape", undefined, "a4");
      pdf.addImage(mapCanvas.toDataURL("image/jpeg"), 0, 0);
      pdf.save("map.pdf");
    }
  }
};
</script>
