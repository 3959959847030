<template>
  <v-card width="350" height="100vh">
    <v-form
      @submit.prevent="
        setComment({
          comment: comment
        })
      "
    >
      <v-col align="center">
        <h3 class="mt-5 mb-2">Комментарий</h3>
        <v-row dense>
          <v-card-text class="text-start">Текст комментария</v-card-text>
          <v-text-field
            type="text"
            v-model="comment"
            outlined
            hide-details
          ></v-text-field>
        </v-row>
        <div class="mt-2">
          <v-row dense justify="center">
            <v-col cols="12" sm="6">
              <v-card-text class="text-center">
                <v-btn type="submit" class="font-weight-bold">Сохранить</v-btn>
              </v-card-text>
            </v-col>
            <v-col v-if="!isDraw" cols="12" sm="6" justify="end">
              <v-card-text class="text-start"
                ><v-btn
                  type="button"
                  class="font-weight-bold"
                  v-on:click="$emit('remove-comment')"
                  >Удалить</v-btn
                ></v-card-text
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "CommentPassport",

  props: { properties: Object, setComment: Function, isDraw: Boolean },

  data() {
    return {
      comment: null
    };
  },

  watch: {
    properties: function(newValue) {
      this.comment = newValue.comment;
    }
  }
};
</script>
