<template>
  <div>
    <v-navigation-drawer width="300" bottom right absolute>
      <v-list>
        <v-list-item>
          <v-list-item-title
            ><h3 class="mt-5 mb-2 text-wrap" style="text-align: center">
              Идентификационный номер
            </h3>
          </v-list-item-title>
          <v-list-item-action>
            <v-btn icon @click="$emit('close')">
              <v-icon size="32">mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item-group color="blue">
          <v-list-item
            v-for="(item, i) in $store.state.search.serialData"
            :key="i"
            @click="menuActionClick(item)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="i + 1"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.properties.id"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { fromLonLat } from "ol/proj";

export default {
  data: () => ({}),
  methods: {
    async menuActionClick(data) {
      let coordinatesX;
      let coordinatesY;
      let type;
      type = data.geometry.type;
      if (type === "Point") {
        coordinatesX = data.geometry.coordinates[0];
        coordinatesY = data.geometry.coordinates[1];
      } else if (type === "Polygon") {
        coordinatesX = data.geometry.coordinates[0][0][0];
        coordinatesY = data.geometry.coordinates[0][0][1];
      } else if (type === "LineString") {
        coordinatesX = data.geometry.coordinates[0][0];
        coordinatesY = data.geometry.coordinates[0][1];
      }

      var view = this.$store.state.settings.map.getView();
      view.setCenter(fromLonLat([coordinatesX, coordinatesY]));
      view.setZoom(21);
      this.$emit("search-data", data.properties.id);
    }
  }
};
</script>
