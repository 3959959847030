<template>
  <div>
    <treeselect
      class="mt-5 mb-2"
      v-model="breedId"
      placeholder="Порода дерева"
      multiple
      :options="breedsRemade"
      @input="passDataChild"
    />
  </div>
</template>

<script>
//Treeselect component
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "BreedCurtainSelect",
  components: { Treeselect },
  props: {
    breedTarget: Array
  },

  data() {
    return {
      breedId: this.breedTarget
    };
  },
  watch: {
    breedTarget: function(val) {
      this.breedId = val;
    }
  },

  computed: {
    breedsRemade() {
      let sort = [];
      this.$store.state.breeds.breedsData.forEach(el => {
        if (el.parent_id !== null) {
          if (el.parent.parent_id !== null) {
            sort.push(el);
          } else {
            sort.unshift(el);
          }
        } else {
          sort.unshift(el);
        }
      });

      let arr = [];
      sort.forEach(e => {
        if (e.parent_id !== null) {
          if (e.parent) {
            let parent = e.parent;
            if (parent.parent) {
              let found = arr.findIndex(
                element => element["id"] === e.parent_id
              );
              if (found !== -1) {
                arr[found]["children"].push({
                  id: e.id,
                  label: e.name,
                  parent_id: e.parent_id
                });
              }
            } else {
              return arr.push({
                id: e.id,
                label: e.name,
                parent_id: e.parent_id,
                children: []
              });
            }
          }
        }
      });

      return arr;
    },
    plantingTypeId() {
      if (this.breedId == null) return null;
      let found = this.$store.state.breeds.breedsData.find(
        element => element["id"] === this.breedId
      );
      let value = null;
      if (found !== -1) {
        let parent = found.parent;
        value = parent.id;
        if (parent.parent) {
          value = parent.parent.id;
        }
      }
      return value;
    },
    plantingTypeName() {
      let found = this.$store.state.breeds.breedsData.find(
        element => element["id"] === this.plantingTypeId
      );
      let name = null;
      if ((found !== -1) & (found !== undefined)) {
        name = found.name;
      }
      return name;
    }
  },

  methods: {
    passDataChild() {
      this.$emit("recieveDataParent", this.breedId);
    }
  }
};
</script>

<style type="text/css">
.vue-treeselect {
  font-size: 12px;
}
.vue-treeselect__control-arrow {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 20px;
}
.vue-treeselect__x {
  width: 14px;
  height: 14px;
  color: #9e9e9e;
}
.vue-treeselect__x-container {
  width: 32px;
  text-align: left;
}
.vue-treeselect--has-value .vue-treeselect__control {
  border: 1px solid #9e9e9e !important;
}

.theme--dark .vue-treeselect__control,
.theme--dark .vue-treeselect__menu {
  background-color: #1e1e1e !important;
}
.theme--dark .vue-treeselect__option--highlight {
  background-color: #5c5c5c !important;
}
.theme--dark .vue-treeselect__single-value {
  color: #ffffff !important;
}
.theme--dark .vue-treeselect__control-arrow,
.theme--dark input {
  color: #ffffff !important;
}
</style>
