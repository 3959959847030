<template>
  <v-form @submit.prevent="searchTool" class="mr-1">
    <v-list-item-action
      class="ma-0 pa-0"
      v-if="$store.state.search.searchByCoord"
    >
      <v-list-item class="ma-0 pa-0">
        <v-text-field
          placeholder="коорд. X"
          type="number"
          min="0"
          oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
          v-model="coordX"
          style="width: 120px"
        ></v-text-field>
        <v-text-field
          placeholder="коорд. Y"
          type="number"
          min="0"
          oninput="if(Number(this.value) < Number(this.min)) this.value = this.min;"
          v-model="coordY"
          style="width: 120px"
        ></v-text-field>
      </v-list-item>
    </v-list-item-action>
    <v-btn
      type="submit"
      icon
      outlined
      title="Смещение карты по координатам"
      :class="{ 'primary--text': coordX !== null && coordY !== null }"
      :disabled="
        $store.state.search.searchByAddress ||
          $store.state.search.searchByID ||
          $store.state.search.searchBySerial
      "
    >
      <v-icon>mdi-map-search-outline</v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import { fromLonLat } from "ol/proj";

export default {
  name: "SearchByCoord",

  data: () => ({
    coordX: null,
    coordY: null
  }),

  methods: {
    searchTool() {
      this.$store.commit(
        "updateSrchCoor",
        !this.$store.state.search.searchByCoord
      );
      if (this.coordX && this.coordY) {
        var view = this.$store.state.settings.map.getView();
        view.setCenter(fromLonLat([this.coordX, this.coordY]));
        view.setZoom(21);
      }
      this.coordX = null;
      this.coordY = null;
    }
  }
};
</script>
